import { Box, Center, Checkbox, Flex, Link, Text } from "@chakra-ui/react";
import { useState } from "react";

import { CloseIcon } from "../CloseIcon";
import { ModalDrawer } from "../ModalDrawer";
import FooterButtons from "../SuccessErrorModalBodyTemplate/components/FooterButtons";

interface StakeDiclaimerProps {
  isOpen: boolean;
  closeAlert: () => void;
  primaryBtnTxt?: string; // view transactions
  onSubmitPrimary?: () => void;
}

const StakeDiclaimer = ({
  isOpen,
  closeAlert,
  primaryBtnTxt,
  onSubmitPrimary,
}: StakeDiclaimerProps) => {
  const [termsAccepted, setTermsAccepted] = useState(true);
  return (
    <ModalDrawer isOpen={isOpen} closeAlert={closeAlert}>
      <Center
        padding={{ base: "0 1rem 2.5rem", md: "2.5rem 3rem" }}
        fontWeight="600"
        flexDirection="column"
        textAlign="center"
        width="100%"
      >
        {
          <>
            <CloseIcon onClose={closeAlert} />
            <Flex flexDir="column" gap="32px">
              <Box textAlign="left">
                <Text
                  my="8px"
                  fontWeight="600"
                  fontSize={{
                    base: "20px",
                    md: "24px",
                  }}
                  lineHeight={{ base: "24px", md: "28px" }}
                >
                  Disclaimer
                </Text>
                <Box>
                  <Text
                    fontWeight="400"
                    fontSize={{
                      base: "12px",
                      md: "14px",
                    }}
                    lineHeight={{
                      base: "18px",
                      md: "20px",
                    }}
                  >
                    SD from the SD Utility Pool will be utilized by ETHx
                    Permissionless NOs to run validators. It will also be used
                    to provide slashing cover to ETHx Permissioned NOs. For
                    further details on participation risks and additional
                    information,{" "}
                    <Link
                      isExternal
                      color="primary"
                      href="https://stader.gitbook.io/stader/sd-utility-pool/disclaimer"
                    >
                      please read more
                    </Link>
                    .
                  </Text>
                </Box>
              </Box>

              <Checkbox
                onChange={() => setTermsAccepted(!termsAccepted)}
                checked={termsAccepted}
                defaultChecked={false}
                iconSize="1.5rem"
              >
                <Text fontSize="14px" fontWeight="500" lineHeight="20px">
                  I have read the disclaimer and understand the risks involved.
                </Text>
              </Checkbox>

              <Box alignSelf="center">
                <FooterButtons
                  primaryBtnTxt={primaryBtnTxt}
                  onSubmitPrimary={onSubmitPrimary}
                  isPrimaryDisabled={termsAccepted}
                />
              </Box>
            </Flex>
          </>
        }
      </Center>
    </ModalDrawer>
  );
};

export default StakeDiclaimer;
